<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="shadow-lg bg-white rounded-lg flex items-center p-5">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-4">
                    <label class="text-gray-400">
                        提領人 手機
                        <div class="flex">
                            <div class="phone-select">
                                <el-select v-model="filterOpts.phone_country" size="small" placeholder="请选择">
                                    <el-option v-for="item in phoneOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                                </el-select>
                            </div>
                            <div>
                                <el-input v-model="filterOpts.phone" size="small" clearable> </el-input>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="col-span-4">
                    <label class="text-gray-400">
                        申請日期 範圍
                        <el-date-picker
                            v-model="dateInputs"
                            size="small"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </label>
                </div>
                <div class="col-span-4 flex items-end justify-end">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px]" @click="goSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px]" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div class="action-bar bg-white rounded-lg mb-10 flex items-center px-5 justify-between">
            <el-checkbox :value="datas.length === choiceOpts.length && choiceOpts.length > 0" @change="choiceAll">全選此頁資料</el-checkbox>
            <div class="flex items-center">
                <div v-if="choiceOpts.length > 0" class="choose-number">已選擇 {{ choiceOpts.length }} 筆提領申請</div>
                <button
                    v-permission="['update']"
                    :disabled="choiceOpts.length == 0"
                    class="orange-btn-outline-800 md:text-sm text-xs h-[30px] mr-2"
                    @click="downloadCsv">
                    匯出成 TXT
                </button>
                <!-- <button @click="moveTO1"
                        class="orange-btn-outline-800  md:text-sm text-xs h-[30px]">移至撥款處理</button> -->
                <button
                    v-permission="['update']"
                    :disabled="choiceOpts.length == 0"
                    class="orange-btn-outline-800 ml-2 md:text-sm text-xs h-[30px]"
                    @click="moveTO2">
                    移至撥款完成
                </button>
            </div>
        </div>
        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <thead>
                    <tr>
                        <th class="w-[20px] checkbox-th"></th>
                        <th>金流編號</th>
                        <th>提領人 暱稱</th>
                        <th>金額</th>
                        <th>申請時間</th>
                        <th>查看</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(item, index) in datas"
                        :key="index"
                        class="text-center duration-200 transition"
                        :class="{
                            'has-export': item.status === 2 ? true : false,
                        }">
                        <td>
                            <el-checkbox :value="hasChecked(item.id)" @change="toggleCheck(item.id)"></el-checkbox>
                        </td>
                        <td>{{ item.transaction_log_id }}</td>
                        <td>{{ item.user.name }}</td>
                        <td>${{ item.amount | formatCurrency }}</td>
                        <td>{{ item.created_at | formatDateTime }}</td>
                        <td class="cursor-pointer" @click="goDetail(item.id)">
                            <i class="far fa-edit"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="justify-center mt-3 flex mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
        <!-- 匯出TXT dialog -->
        <PopupInputDate v-model="showDialog" :param="dialogParam" @confirm="exportTxt" />
    </div>
</template>

<script>
// import
import fileDownload from "js-file-download";
import moment from "moment";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 對帳相關 filters
import "../filterAccount";
// options phone
import { phoneOptions } from "@/config/optionsConfig.js";
// dialog
import PopupInputDate from "@/components/popup/PopupInputDate.vue";

// init search Filter options
const defaultFilterOpts = {
    transaction_log_id: "",
    phone_country: "886",
    phone: "",
    status: [1, 2], // 0(服務商或系統提出提領), 1(會計確認無誤), 2(匯出銀行檔案), 3(完成匯款), -1(發生錯誤)
    reason: "WITHDRAW",
    created_date_start: "",
    created_date_end: "",
};
export default {
    name: "ImmediatelyWithdraw",
    components: {
        LoadingComponent,
        MyPagination,
        PopupInputDate,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const phone = this.filterOpts.phone ? `${this.filterOpts.phone_country}${this.filterOpts.phone}` : "";
            return {
                ...this.filterOpts,
                phone: phone,
                limit: this.paginationData.limit,
                page: this.currentPage,
                phone_country: "886",
            };
        },
        // 手機國碼
        phoneOptions() {
            return phoneOptions;
        },
    },
    data() {
        return {
            loading: true,
            datas: [],
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 0,
            filterOpts: { ...defaultFilterOpts },
            dateInputs: [], // 時間範圍
            choiceOpts: [], // 已選擇之金流id,
            showDialog: false,
            dialogParam: {
                title: "匯出成 TXT",
                message: "請輸入預計匯款日期",
            },
        };
    },
    methods: {
        // 列表搜尋相關
        async getList() {
            this.loading = true;
            this.choiceOpts = [];
            const { status, data } = await this.$api
                .SearchRemittancesAPI(this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        reset() {
            this.dateInputs = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        // 選擇相關
        choiceAll(val) {
            if (val) {
                this.datas.forEach((item) => {
                    const id = item.id;
                    if (!this.choiceOpts.includes(id)) {
                        this.choiceOpts.push(id);
                    }
                });
            } else {
                this.choiceOpts = [];
            }
        },
        toggleCheck(id) {
            const hasChoice = this.choiceOpts.indexOf(id) !== -1;
            if (hasChoice) {
                this.choiceOpts = this.choiceOpts.filter((item) => {
                    return item !== id;
                });
            } else {
                this.choiceOpts.push(id);
            }
        },
        hasChecked(idx) {
            return this.choiceOpts.indexOf(idx) !== -1;
        },
        // 功能相關
        downloadCsv() {
            this.showDialog = true;
        },
        // 匯出TXT
        async exportTxt(date) {
            const request = {
                ids: this.choiceOpts,
                date: date,
            };
            // 確認資料是否有異常
            const { passed, failed, status } = await this.$api
                .CheckRemittances(request)
                .then((res) => {
                    return {
                        passed: [...res.data.passed],
                        failed: [...res.data.failed],
                        status: res.status,
                    };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "匯出資料失敗",
                    });
                    return { status: 400 };
                });

            // 匯出無異常資料
            if (status == 200 && passed.length > 0) {
                request.ids = [...passed];
                await this.$api
                    .ExportRemittanceText(request)
                    .then((res) => {
                        fileDownload(res.data, `${moment().format("YYYYMMDD")}.txt`);
                    })
                    .catch(() => {
                        this.$message({
                            type: "error",
                            message: "匯出資料失敗",
                        });
                    });
            }
            if (failed.length > 0) {
                alert(`有${failed.length}筆資料資訊異常，已移至異常處理`);
            }
            this.getList();
        },
        moveTO2() {
            const opts = {
                title: "移至撥款完成",
                message: "要執行此操作，請在下面的表格中輸入 CONFIRM。",
            };
            this.$pop.popConfirmType(opts).then(
                () => {
                    this.changeStatusSelects(3);
                },
                () => {
                    //取消
                    console.log("cancel");
                }
            );
        },
        // 連結相關
        goDetail(paymentID) {
            this.$router.push({
                name: "stored_detail",
                params: {
                    paymentID,
                },
                query: {
                    pre: this.$route.name,
                },
            });
        },
        // 更新狀態
        changeStatusSelects(status = 2) {
            const request = {
                ids: this.choiceOpts,
                status,
            };
            this.$api
                .UpdateRemittanceStatusArray(request)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "更新成功",
                    });
                    this.getList();
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({
                        type: "error",
                        message: "更新失敗",
                    });
                })
                .finally(() => {
                    this.getList();
                });
        },
        changeStatusApi(id, status) {
            const request = {
                status,
            };
            return this.$api.UpdateRemittanceStatus(id, request);
        },
    },
    watch: {
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.created_date_start = "";
                this.filterOpts.created_date_end = "";
                return;
            }
            this.filterOpts.created_date_start = val[0];
            this.filterOpts.created_date_end = val[1];
        },
        "filterOpts.phone"(val) {
            if (this.$isEmpty(val)) {
                return;
            }
            if (val[0] == 0 && val[1] == 9) {
                this.filterOpts.phone = val.substring(1);
                return;
            }
        },
    },
    mounted() {
        this.getList();
    },
    activated() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

.has-export {
    background: #be614c24 !important;
}

.choose-number {
    font-size: 14px;
    font-weight: 400;
    color: #757575;
    margin-right: 30px;
}

.phone-select {
    width: 150px;
    margin-right: 5px;
}

.action-bar {
    background: white;
    color: chocolate;
    margin: 10px 0;
    height: 55px;
}

.checkbox-th {
    min-width: auto;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
